import $axios from '../api'

const auth = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    authin ({ commit }, payload) {
        commit('SET_TOKEN', null, { root: true })
        return new Promise((resolve, reject) => {
            $axios.post('login', payload).then((response) => {
                let token = response.data.access_token
                localStorage.setItem('token', token)
                commit('SET_TOKEN', token, { root: true })
                $axios.get(`profile`).then((res) => {
                  localStorage.setItem('userdata', JSON.stringify(res.data))
                  commit('SET_USERDATA', res.data, { root: true })
                  resolve(response.data)
                }).catch((error) => {
                  var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                  }
                  reject(reserr)
                })
              })
              .catch((error) => {
                var reserr = {
                  statusText : error.response ? error.response.statusText : error,
                  data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    authout ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', null, { root: true })
            commit('SET_USERDATA', null, { root: true })
            commit('SET_LISTALERTDSB', null, { root: true })
            commit('SET_LISTALERT', null, { root: true })
            resolve(true)
        });
    },
  },
  getters: { }
}

export default auth