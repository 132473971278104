import $axios from '../api'

const weather = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('awos/weathers', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('awos/input-manual-weather', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`awos/show-manual-weather/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updateWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post(`awos/update-manual-weather/${payload.uuid}`, payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`awos/delete-manual-weather/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    formdataWTR ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('awos/form-manual-weather').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    indexDWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('manual-input', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeDWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('manual-input', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showDWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`manual-input/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updateDWTR ({ commit }, payload) {
        let uuid = payload.get('uuid')
        return new Promise((resolve, reject) => {
            $axios.post(`manual-input/${uuid}`, payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyDWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`manual-input/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    formdataDWTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('manual-input/form', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default weather