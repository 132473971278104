import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Pages
const Page500 = () => import('@/views/pages/Page500')
const Page404 = () => import('@/views/pages/Page404')
const Login = () => import('@/views/pages/Login')

// Views
const Home = () => import('@/views/pages/Home')

import adminRouter from "./admin"

import store from '../store'

Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes () {
  return [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/pages',
      redirect: '/pages/404',
      name: 'Pages',
      component: {
        render (c) { return c('router-view') }
      },
      children: [
        {
          path: '404',
          name: 'Page404',
          component: Page404
        },
        {
          path: '500',
          name: 'Page500',
          component: Page500
        },
      ]
    },
    {   
      path: '/',
      redirect: '/home',
      name: 'Homes',
      component: TheContainer,
      meta: {
        requiresAuth: true
      },
      children: [
        {
          path: 'home',
          name: 'Home',
          component: Home
        },
        ...adminRouter,
      ]
    }
  ]
}

router.beforeEach(async (to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    var token = store.state.token
    if (token == null) {
      next({
        path: '/login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

