import $axios from '../api'

const contact = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexCTC ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('contacts', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeCTC ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('contacts/add', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyCTC ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('contacts/delete', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                  statusText : error.response ? error.response.statusText : error,
                  data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    unlistCTC ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('contacts/unlisted').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default contact