import $axios from '../api'

const position = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexPST ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get('job-positions').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storePST ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('job-positions', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showPST ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`job-positions/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updatePST ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.put(`job-positions/${payload.uuid}`, payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyPST ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`job-positions/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default position