import $axios from '../api'

const configs = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexCFG ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('settings?for=cc').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeCFG ({ commit }, payload) {
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post('settings?for=cc', payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    // Asset
    indexACFG ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('settings?for=asset').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeACFG ({ commit }, payload) {
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post('settings?for=asset', payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    // Lanud
    indexLCFG ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('integration-settings').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeLCFG ({ commit }, payload) {
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post('integration-settings', payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    indexUCFG ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('profile/airport-preferences').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeUCFG ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('profile/airport-preferences', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default configs