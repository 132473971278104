import $axios from '../api'

const airport = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    listAPT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('airports/list', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    indexAPT ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('airports').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeAPT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('airports', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showAPT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`airports/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updateAPT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.put(`airports/${payload.uuid}`, payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyAPT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`airports/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default airport