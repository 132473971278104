import $axios from '../api'

const airportradar = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexAPTR ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('airport-radars').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeAPTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('airport-radars', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showAPTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`airport-radars/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updateAPTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.put(`airport-radars/${payload.uuid}`, payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyAPTR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`airport-radars/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default airportradar