import $axios from '../api'

const division = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexDVS ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get('divisions').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeDVS ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('divisions', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showDVS ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`divisions/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updateDVS ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.put(`divisions/${payload.uuid}`, payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyDVS ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`divisions/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default division