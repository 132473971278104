import $axios from '../api'

const chatgroup = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexCGP ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('chats').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    showCGP ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('chats/show-room-chat', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeCGP ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('chats/send-chat', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    storeGRP ({ commit }, payload) {
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post('groups', payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showGRP ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`groups/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updateGRP ({ commit }, payload) {
        let uuid = payload.get('uuid')
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post(`groups/${uuid}`, payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyGRP ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`groups/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    listGRP ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('groups/list', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default chatgroup