import $axios from '../api'

const profile = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    showPFL ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get(`profile`).then((response) => {
                localStorage.setItem('userdata', JSON.stringify(response.data))
                commit('SET_USERDATA', response.data, { root: true })
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updatePFL ({ commit }, payload) {
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post(`profile`, payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    changepwPFL ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          $axios.put(`profile/change-password`, payload).then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              var reserr = {
                  statusText : error.response ? error.response.statusText : error,
                  data : error.response ? error.response.data : null
              }
              reject(reserr)
          })
      });
    },
    getuserUNT ({ commit }) {
      return new Promise((resolve, reject) => {
          $axios.get(`profile/awos-unit-setting`).then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              var reserr = error.response ? error.response.statusText : error
              reject(reserr)
          })
      });
    },
    changeuserUNT ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          $axios.post(`profile/awos-unit-setting`, payload).then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              var reserr = {
                  statusText : error.response ? error.response.statusText : error,
                  data : error.response ? error.response.data : null
              }
              reject(reserr)
          })
      });
    },
  },
  getters: { }
}

export default profile