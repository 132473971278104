import $axios from '../api'

const dashboard = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexDSH ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('dashboard/command-center', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    indexAWOS ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('awos/logs', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    indexRADAR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('radar/logs', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    dataMGT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('data-management', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    storeAWOS ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('dashboard/awos', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    storeRADAR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('dashboard/radar', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    // Asset
    indexADSH ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('dashboard').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    indexAMG ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('dashboard2').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    indexLOG ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('asset-log/list', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default dashboard