import $axios from '../api'

const report = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    storeRST ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('awos-reports/specific-time', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    printRST ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('awos-reports/specific-time', payload, {responseType: 'arraybuffer'}).then((response) => {
                let blob = new Blob([response.data], { type: 'application/pdf' } )
                var url = window.URL.createObjectURL(blob)
                resolve(url)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeRRT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('awos-reports/range', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    printRRT ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('awos-reports/range', payload, {responseType: 'arraybuffer'}).then((response) => {
                let blob = new Blob([response.data], { type: 'application/pdf' } )
                var url = window.URL.createObjectURL(blob)
                resolve(url)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    // Asset
    showList ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`report-detail/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    showPdf ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`print-report-detail/${payload}`, null, {responseType: 'arraybuffer'}).then((response) => {
                let blob = new Blob([response.data], { type: 'application/pdf' } )
                var url = window.URL.createObjectURL(blob)
                resolve(url)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default report