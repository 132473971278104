import $axios from '../api'

const users = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    indexUSR ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('users').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    storeUSR ({ commit }, payload) {
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post('users', payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    showUSR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`users/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    updateUSR ({ commit }, payload) {
        let uuid = payload.get('uuid')
        let config = { headers: { 'Content-Type': 'multipart/form-data;boundary=someArbitraryUniqueString' } }
        return new Promise((resolve, reject) => {
            $axios.post(`users/${uuid}`, payload, config).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = {
                    statusText : error.response ? error.response.statusText : error,
                    data : error.response ? error.response.data : null
                }
                reject(reserr)
            })
        });
    },
    destroyUSR ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.delete(`users/${payload}`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
  },
  getters: { }
}

export default users

/* Single request */
// let config = { headers: { 'Content-Type': 'multipart/form-data', } }
// $axios.post('URL', data, config).then((res) => {})

/* Global Config */
// $axios.defaults.responseType = 'arraybuffer' /* arraybuffer or blob */
// $axios.defaults.headers.common['Content-Type'] = 'multipart/form-data;boundary=someArbitraryUniqueString' // for all requests
// $axios.defaults.headers.post['Content-Type'] = 'multipart/form-data' // for POST requests

/* default instance */
// let instance = $axios.create({ headers: { post: { // can be common or any other method
//     Content-Type: 'multipart/form-data'
// } } })
// //- or after instance has been created
// instance.defaults.headers.post['Content-Type'] = 'multipart/form-data'
// //- or before a request is made // using Interceptors
// instance.interceptors.request.use(config => {
// config.headers.post['Content-Type'] = 'value';
// return config;
// });