import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store'
import Multiselect from 'vue-multiselect'
import vSelect from 'vue-select'
import 'vue-awesome/icons'
import Icon from 'vue-awesome/components/Icon'
import SvgPanZoom from 'vue-svg-pan-zoom'
import panZoom from 'vue-panzoom'
import FlashMessage from '@smartweb/vue-flash-message'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.use(FlashMessage, {strategy: 'multiple', time: '5000'})
// Vue.prototype.$log = console.log.bind(console)

Vue.component('multiselect', Multiselect)
Vue.component('v-select', vSelect)
Vue.component('v-icon', Icon)
Vue.component('SvgPanZoom', SvgPanZoom)
Vue.use(panZoom)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
