import $axios from '../api'

const notification = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    showallNTF ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.post('notifications/list', payload).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    showunreadNTF ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get(`notifications/unread?for=cc`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    showunreadANTF ({ commit }) {
      return new Promise((resolve, reject) => {
          $axios.get(`notifications/unread?for=asset`).then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              var reserr = error.response ? error.response.statusText : error
              reject(reserr)
          })
      });
    },
    readallNTF ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get(`notifications/mark-all-as-read?for=cc`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    readallANTF ({ commit }) {
      return new Promise((resolve, reject) => {
          $axios.get(`notifications/mark-all-as-read?for=asset`).then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              var reserr = error.response ? error.response.statusText : error
              reject(reserr)
          })
      });
    },
    readNTF ({ commit }, payload) {
        return new Promise((resolve, reject) => {
            $axios.get(`notifications/${payload}/mark-as-read`).then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    showNTF ({ commit }, payload) {
      return new Promise((resolve, reject) => {
          $axios.get(`notifications/${payload}`).then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              var reserr = error.response ? error.response.statusText : error
              reject(reserr)
          })
      });
    },
  },
  getters: { }
}

export default notification