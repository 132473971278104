const Dashboard = () => import('@/views/iwcs/Dashboard')
const Awos = () => import('@/views/iwcs/Awos')
const Radar = () => import('@/views/iwcs/Radar')
const MapView = () => import('@/views/iwcs/MapView')
const DataManagement = () => import('@/views/iwcs/DataManagement')
const ChatGroup = () => import('@/views/iwcs/ChatGroup')
const Contact = () => import('@/views/iwcs/Contact')
const AwosLogs = () => import('@/views/iwcs/AwosLogs')
const RadarLogs = () => import('@/views/iwcs/RadarLogs')
const Reports = () => import('@/views/iwcs/Reports')
// Users
const Users = () => import('@/views/iwcs/Users/Index')
const FormUsers = () => import('@/views/iwcs/Users/Form')
const DetailUsers = () => import('@/views/iwcs/Users/Detail')
const Config = () => import('@/views/iwcs/Config')
const Position = () => import('@/views/iwcs/Position/Index')
const Airport = () => import('@/views/iwcs/Airport/Index')
const Profile = () => import('@/views/iwcs/Profile/Index')
const Role = () => import('@/views/iwcs/Role/Index')
const Division = () => import('@/views/iwcs/Division/Index')
const Unit = () => import('@/views/iwcs/Unit/Index')
const Notification = () => import('@/views/pages/Notification')
const License = () => import('@/views/pages/License')

const admin = [
    { path: 'dashboard', name: 'Dashboard', component: Dashboard, },
    { path: 'awos', name: 'Awos', component: Awos, },
    { path: 'radar', name: 'Radar', component: Radar, },
    { path: 'map_view', name: 'Map View', component: MapView, },
    { path: 'data_management', name: 'Data Management', component: DataManagement, },
    { path: 'chat_group', name: 'Chat Group', component: ChatGroup, },
    { path: 'contact', name: 'Contact', component: Contact, },
    { path: 'awos_logs', name: 'Awos Logs', component: AwosLogs, },
    { path: 'radar_logs', name: 'Radar Logs', component: RadarLogs, },
    { path: 'reports', name: 'Reports', component: Reports, },
    // Users
    { path: 'users', name: 'Users', component: Users, },
    { path: 'users/add', name: 'Add Users', component: FormUsers, },
    { path: 'users/:id/edit', name: 'Edit Users', component: FormUsers },
    { path: 'users/:id/detail', name: 'Detail Users', component: DetailUsers },
    { path: 'config', name: 'Config', component: Config, },
    // Position
    { path: 'position', name: 'Position', component: Position },
    // Airport
    { path: 'airport', name: 'Airport', component: Airport },
    // Profile
    { path: 'profile', name: 'Profile', component: Profile },
    // Role
    { path: 'role', name: 'Role', component: Role },
    // Division
    { path: 'division', name: 'Division', component: Division },
    // Unit
    { path: 'unit', name: 'Unit', component: Unit },
    // Notification
    { path: 'notification', name: 'Notification', component: Notification },
    { path: 'license', name: 'License', component: License },
]

export default admin