import $axios from '../api'

const information = {
  namespaced: true,
  state: { },
  mutations: { },
  actions: {
    infoCC ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('system-information?for=cc').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    // Asset
    infoAsset ({ commit }) {
        return new Promise((resolve, reject) => {
            $axios.get('system-information?for=asset').then((response) => {
                resolve(response.data)
              })
              .catch((error) => {
                var reserr = error.response ? error.response.statusText : error
                reject(reserr)
            })
        });
    },
    // Lanud
    infoLanud ({ commit }) {
      return new Promise((resolve, reject) => {
          $axios.get('integration-system-information').then((response) => {
              resolve(response.data)
            })
            .catch((error) => {
              var reserr = error.response ? error.response.statusText : error
              reject(reserr)
          })
      });
    },
  },
  getters: { }
}

export default information