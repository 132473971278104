import Vue from 'vue'
import Vuex from 'vuex'

// import module
import auth from './store/auth'
import users from './store/users'
import position from './store/position'
import airport from './store/airport'
import airportradar from './store/airportradar'
import profile from './store/profile'
import role from './store/role'
import division from './store/division'
import unit from './store/unit'
import dashboard from './store/dashboard'
import maintenance from './store/maintenance'
import notification from './store/notification'
import weather from './store/weather'
import contact from './store/contact'
import chatgroup from './store/chatgroup'
import configs from './store/configs'
import report from './store/report'
import information from './store/information'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  token: localStorage.getItem('token'),
  userdata: JSON.parse(localStorage.getItem('userdata')),
  listalertdsb: JSON.parse(localStorage.getItem('listalertdsb')),
  listalert: JSON.parse(localStorage.getItem('listalert')),
  errors: [],
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  },
  SET_TOKEN (state, payload) {
    state.token = payload
  },
  SET_USERDATA (state, payload) {
    state.userdata = payload
  },
  SET_LISTALERTDSB (state, payload) {
    state.listalertdsb = payload
  },
  SET_LISTALERT (state, payload) {
    state.listalert = payload
  },
  SET_ERROR(state, payload) {
    state.errors = payload
  },
}

const getters = {
  isAuth: state => state.token ? true : false
}

export default new Vuex.Store({
  modules: {
    auth,
    users,
    position,
    airport,
    airportradar,
    profile,
    role,
    division,
    unit,
    dashboard,
    maintenance,
    notification,
    weather,
    contact,
    chatgroup,
    configs,
    report,
    information
  },
  state,
  mutations,
  getters
})